import { __ } from '@adac/core-model';
import { Icon, Modal, Text, Title, View } from '@adac/core-view';
import { useState } from 'react';
import styled from 'styled-components';

export function PhoneContactModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <View key={String(isModalVisible)}>
      <ContactModal on={isModalVisible} onClose={() => setIsModalVisible(false)} />
      <View onClick={() => setIsModalVisible(true)} padding>
        <Icon name='phone' round invert color='white' />
      </View>
    </View>
  );
}

const ModalStyles = styled(Modal)`
  width: 80%;
  padding: 16px;
`;

interface ContactModalProps {
  on: boolean;
  onClose: () => void;
}

const OptionList = styled.ul`
  margin: 6px;
`;

const Option = styled.li`
  font-size: 1.6rem;
  list-style: disc;
  margin-left: 16px;
  padding-left: 8px;
`;

export function ContactModal({ on, onClose }: ContactModalProps) {
  return (
    <ModalStyles on={on} close={onClose}>
      <Title margin>{__('Phone Numbers for Inquiries')}</Title>
      <Text>{__('If you have any questions or problems, please contact one of the following phone numbers')}</Text>
      <OptionList>
        <Option>
          <Text>{__('Technical Questions About the System')}</Text>
          <a href='tel:08976761414'>
            <Text underline>089 76 76 14 14</Text>
          </a>
        </Option>
        <Option>
          <Text>{__('Questions About the Current Order/Customer')}</Text>
          <a href='tel:08976763399'>
            <Text underline>089 76 76 33 99</Text>
          </a>
        </Option>
      </OptionList>
    </ModalStyles>
  );
}
