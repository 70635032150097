import { __, AllBasePriceTypes, config } from '@adac/core-model';
import { getFinalReportTypeFromPathname, getOfferText, OfferPriceView, Text, useLocation } from '@adac/core-view';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import StoreContext from '../../stores';

interface PriceDetailsProps {
  baseAmountType?: AllBasePriceTypes;
  appointementDate?: Date;
  children?: React.ReactNode;
}
export const PriceDetails = observer(({ baseAmountType, appointementDate, children }: PriceDetailsProps) => {
  const isInsurance = useIsInsuranceProduct();
  const { case: caseStore } = useContext(StoreContext);

  const { pathname } = useLocation();
  const finalReportType = getFinalReportTypeFromPathname(pathname);

  const offerText = caseStore.scheduledTs
    ? `${__('Agreed appointment time')} ${config.date(caseStore.scheduledTs, 'dddd, \nDD.MM.YYYY, HH:mm')} Uhr `
    : getOfferText(appointementDate, config.date(appointementDate, 'dddd, '));

  return (
    <>
      {!isInsurance && finalReportType !== 'partner' && <OfferPriceView type={baseAmountType} />}
      <Text margin light>
        {offerText}
      </Text>
      {children}
    </>
  );
});
