/* eslint-disable no-return-await */
import { useContext } from 'react';

import {
  ApiDataLoaded,
  Button,
  createAddressString,
  Divider,
  ErrorMessage,
  extractConfirmedAddressObject,
  getFinalReportTypeFromPathname,
  InfoItem,
  LinkAutoPrefixer,
  ProductType,
  SubTitle,
  Text,
  Title,
  useLocation,
  UserInfo,
  useView,
} from '@adac/core-view';

import { __, AdditionalCost, CaseReportDTO, formatDate, LocationAddress } from '@adac/core-model';

import { Form, useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import StoreContext from '../../stores';
import FinalFormBillingAddress from '../composite/FinalFormBillingAddress';
import FinalFormSignatureView from '../composite/FinalFormSignatureView';
import PhotoList from '../composite/PhotoList';
import { PriceDetails } from '../composite/PriceDetails';
import { PriceList } from '../composite/PriceList';
import { FinalReportFormValues } from './FinalReport';
import { ReceiveDocuments } from './ReceiveDocuments';

const concatPriceItems = (additionalCosts?: AdditionalCost[], baseAmount?: number) => [
  ...(baseAmount ? [{ cost: +baseAmount, description: 'BasePrice', count: 1 }] : []),
  ...(additionalCosts || []),
];

interface FinalReportFormProps {
  action: {
    hasLoaded: boolean;
    resource: CaseReportDTO | null;
  };
}

const FinalReportForm = observer(({ action: { resource, hasLoaded }, ...props }: FinalReportFormProps) => {
  const { isSubmitting, setFieldValue, values, errors, isValid } = useFormikContext<FinalReportFormValues>();

  const {
    case: caseStore,
    beforeOpeningDoorPhotos: beforeDoorStore,
    beforeExtraDamageStarting: beforeExtraDamage,
    afterOpeningDoorPhotos: afterDoorStore,
  } = useContext(StoreContext);

  const { pathname } = useLocation();
  const { isView } = useView();

  const {
    finalReport,
    comissionOpening,
    extraDamageExtraCost,
    additionalCosts,
    baseAmount,
    baseAmountType,
    totalAmount,
    billingAddress: billingAddressFromServer,
    confirmedAddress: confirmedAddressFromServer,
    case: caseObj,
    caseAppointmentDate,
  } = useResourceForReportType(resource || {});

  const finalConfirmedAddress: LocationAddress | undefined =
    extractConfirmedAddressObject(confirmedAddressFromServer) || extractConfirmedAddressObject(caseStore);
  const finalConfirmedAddressString = finalConfirmedAddress ? createAddressString(finalConfirmedAddress) : caseStore.customerFullAddressString;

  const user = {
    firstName: caseObj?.customerFirstName || caseStore.customerFirstName,
    lastName: caseObj?.customerFamilyName || caseStore.customerFamilyName,
    title: caseObj?.title || caseStore.title || '',
    address: createAddressString(caseObj),
  };

  const isInsurance = useIsInsuranceProduct();

  return (
    <Form>
      <ApiDataLoaded hasLoaded={hasLoaded} />
      {!isView && (
        <LinkAutoPrefixer to='/' prefix='rootId'>
          <SubTitle>&lt;{__('Back')}</SubTitle>
        </LinkAutoPrefixer>
      )}
      <Title margin>{__(isInsurance ? 'Progress report' : 'Tätigkeitsbericht & Kostenaufstellung')}</Title>
      <PriceDetails baseAmountType={baseAmountType} appointementDate={caseAppointmentDate}>
        <PriceList
          productType={caseStore.productType}
          pathname={pathname}
          items={concatPriceItems(additionalCosts, baseAmount)}
          finalPrice={totalAmount}
        />
      </PriceDetails>
      <Divider fullWidth margin='24px' />
      <UserInfo {...user} />
      {finalConfirmedAddressString !== caseStore.customerFullAddressString && (
        <InfoItem header={__('Confirmed Customer Address')} info={finalConfirmedAddressString} />
      )}
      {!isInsurance && (
        <FinalFormBillingAddress {...props} billingAddressFromServer={billingAddressFromServer} addressString={caseStore.customerFullAddressString} />
      )}
      <ReceiveDocuments
        phoneNumber={caseStore.customerPhone}
        email={values.email || undefined}
        onEmailChanged={(newEmail) => setFieldValue('email', newEmail)}
        receiveByEmail={values.receiveByEmail}
        setReceiveByEmail={(value) => setFieldValue('receiveByEmail', value)}
      />
      {!isView && errors.email && <ErrorMessage>{__('please enter a valid email address')}</ErrorMessage>}
      <ProductType productType={caseStore.productType} productNumber={caseStore.productNumber} damageNumber={caseStore.damageNumber} />
      <InfoItem header='Datum' info={formatDate(caseAppointmentDate)} />
      <SubTitle margin>{__('Photo of door before opening')}</SubTitle>
      {comissionOpening && comissionOpening.photos.length === 0 && <Text>{__('No damage')}</Text>}
      <PhotoList doorStore={beforeDoorStore} reportPhotos={comissionOpening && comissionOpening.photos}>
        {__('description of damage before door opening')}
      </PhotoList>
      <SubTitle margin>{__('Photo of door extra damage')}</SubTitle>
      {!extraDamageExtraCost?.photos?.length && <Text>{__('No damage')}</Text>}
      <PhotoList doorStore={beforeExtraDamage} reportPhotos={extraDamageExtraCost && extraDamageExtraCost.photos}>
        {__('description of damage before door opening')}
      </PhotoList>
      <SubTitle margin>{__('Photo of door after opening')}</SubTitle>
      {((finalReport && finalReport.photos.length === 0) ||
        (!isView && afterDoorStore && afterDoorStore.photosUploadedByUser && afterDoorStore.photosUploadedByUser.length === 0)) && (
        <Text>{__('Everything was fine (no picture)')}</Text>
      )}
      <PhotoList doorStore={afterDoorStore} reportPhotos={(finalReport && finalReport.photos) || []}>
        {__('description of damage after door opening')}
      </PhotoList>
      <SubTitle margin>{__('Signature')}</SubTitle>
      <Text margin>{__('Work completed')}</Text>
      <FinalFormSignatureView finalReport={finalReport} />
      {!isView && errors.photos !== undefined && (
        <ErrorMessage>{__('Error: photos: [missing "en.validation.errorMessages.array.min" translation]')}</ErrorMessage>
      )}
      {!isView && <Button type='submit' cta title={__('Confirm')} disabled={!isValid || isSubmitting} isLoading={isSubmitting} />}
    </Form>
  );
});
export default FinalReportForm;

function useResourceForReportType(resource: CaseReportDTO) {
  const { pathname } = useLocation();
  const finalReportType = getFinalReportTypeFromPathname(pathname);

  if (finalReportType === 'partner') {
    const resourceForPartner = {
      ...resource,
      baseAmount: resource?.partner?.baseAmount,
      baseAmountType: resource?.partner?.baseAmountType,
      totalAmount: resource?.partner?.totalAmount,
      caseAppointmentDate: resource?.partner?.start,
    };

    return resourceForPartner;
  }

  return resource;
}
