import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { Cancellation, CaseActions, CaseStatusList, ProductTypes, __, getCaseActionName, keyGenerator } from '@adac/core-model';
import { Page, PageTransitionContainer, StartPage, StatusRender, findMatchingStatusComponent } from '@adac/core-view';

import swal from 'sweetalert';
import StoresContext from '../../stores';

import { useStatusListeners } from '../../hooks/useStatusListener';
import AuthorizationRenderer from '../composite/AuthorizationRenderer';
import { HubbleCommissionFeedbackRetry } from '../composite/HubbleCommissionFeedbackRetry';
import { CaseCompleted } from '../pages/CaseCompleted';
import ComissionOpeningForm from '../pages/ComissionOpeningForm';
import CommissionExtraCost from '../pages/CommissionExtraCost';
import DoorOpeningStarted from '../pages/DoorOpeningStarted';
import IncomingDriverJob from '../pages/IncomingDriverJob';
import IncomingJobToDispatch, { IncomingJobControls } from '../pages/IncomingJobToDispatch';

interface RouterProps {
  afterElement?: JSX.Element;
}

const shouldNotifyStates: string[] = [CaseStatusList.SND_SELECTED, CaseStatusList.SND_SCHEDULED, CaseStatusList.DRIVER_DEPARTED];

export default observer(({ afterElement }: RouterProps): JSX.Element => {
  const { case: caseStore, ui: uiStore } = useContext(StoresContext);

  useStatusListeners(caseStore, async (data) => {
    const shouldNotify = shouldNotifyStates.includes(caseStore.statusData.event) && data?.event === getCaseActionName(CaseActions.UPDATE_CASE);
    if (shouldNotify) {
      swal({
        title: __('status:UPDATE_CASE'),
        text: __('Case update'),
        icon: 'warning',
        dangerMode: true,
      });
    }
  });

  const getStatusMessage = () => {
    const { cancellation, status, productType } = caseStore;

    if (cancellation === Cancellation.EMPTY_RIDE) {
      return 'Case has been withdrawn';
    }

    if (
      cancellation === Cancellation.CANCELLED ||
      cancellation === Cancellation.EXPIRED ||
      cancellation === Cancellation.REJECTED ||
      cancellation === Cancellation.MANUALLY_CLOSED
    ) {
      return 'Case has been cancelled';
    }

    if (status === CaseStatusList.CASE_COMPLETED && productType === ProductTypes.PREMIUM) {
      return 'Job is closed successfully!';
    }

    return 'Job is closed successfully with documents!';
  };

  const statusMessage = getStatusMessage();

  const routes: JSX.Element[] = [
    <StatusRender status={caseStore.status} onStatus={[CaseStatusList.START]} render={<StartPage />} />,
    <StatusRender
      status={caseStore.status}
      onStatus={[CaseStatusList.SND_SELECTED, CaseStatusList.SND_SCHEDULED]}
      render={
        <AuthorizationRenderer>
          <IncomingJobToDispatch>
            <IncomingJobControls />
          </IncomingJobToDispatch>
        </AuthorizationRenderer>
      }
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[CaseStatusList.DRIVER_SELECTED, CaseStatusList.SCHEDULED_DRIVER_SELECTED]}
      render={
        <AuthorizationRenderer>
          <IncomingJobToDispatch>
            <HubbleCommissionFeedbackRetry>
              <IncomingJobControls />
            </HubbleCommissionFeedbackRetry>
          </IncomingJobToDispatch>
        </AuthorizationRenderer>
      }
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[CaseStatusList.DRIVER_DEPARTED, CaseStatusList.DRIVER_SCHEDULED, CaseStatusList.DRIVER_APPROACHING]}
      render={
        <AuthorizationRenderer>
          <IncomingDriverJob />
        </AuthorizationRenderer>
      }
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[CaseStatusList.DRIVER_ARRIVED]}
      render={
        <AuthorizationRenderer>
          <ComissionOpeningForm />
        </AuthorizationRenderer>
      }
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[CaseStatusList.OPENING_STARTED]}
      render={
        <AuthorizationRenderer>
          <DoorOpeningStarted />
        </AuthorizationRenderer>
      }
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[CaseStatusList.EDITING_EXTRA_DAMAGE, CaseStatusList.EXTRA_DAMAGE_ONGOING]}
      render={
        <AuthorizationRenderer>
          <CommissionExtraCost />
        </AuthorizationRenderer>
      }
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[CaseStatusList.OPENING_COMPLETED, CaseStatusList.IMAGES_UPDATED, CaseStatusList.CASE_COMPLETED]}
      render={
        <AuthorizationRenderer unauthorizedView={<CaseCompleted>{__('Job has already taken')}</CaseCompleted>}>
          <CaseCompleted>{__(statusMessage)}</CaseCompleted>
        </AuthorizationRenderer>
      }
    />,
  ];

  // TODO: enable pagetransitions again
  const foundMatchingRoute = findMatchingStatusComponent(routes, caseStore.status);
  // const foundMatchingRoute = findMatchingRoute({ status: caseStore.status, routes });
  if (foundMatchingRoute && foundMatchingRoute.props.pageTransition) {
    // NOTE: The reason I don't use pageTranstion just a local reference, using the stores we might be able to have more control, and manage from other place
    uiStore.setPageTransition(foundMatchingRoute && foundMatchingRoute.props.pageTransition);
  } else {
    uiStore.setPageTransition();
  }

  return (
    <PageTransitionContainer
      pageTransition={uiStore.pageTransition}
      pageTransitionDelay={uiStore.pageTransitionDelay}
      routes={routes}
      transitionKey={caseStore.status}
      ContentStyle={Page}
    >
      <>
        {routes.map((RouteConfig) =>
          React.cloneElement(RouteConfig, {
            key: keyGenerator(RouteConfig.props, ['onStatus']),
          })
        )}
        {/* After elements */}
        {afterElement}
      </>
    </PageTransitionContainer>
  );
});
